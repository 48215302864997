<template>
  <v-container class="px-0">
    <v-row>
      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Link</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in files" :key="item.name">
                <td>{{ item.name }}</td>
                <td>
                  <v-btn
                    text
                    color="primary"
                    class="px-0"
                    :href="item.url"
                    target="_blank"
                  >
                    <span>Download</span>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import data from "@/models/data";
import _ from "lodash";

export default {
  name: "ResultsFiles",
  data: () => ({
    files: [],
  }),
  methods: {
    async getResults() {
      const modelID = this.$store.state.results.modelID;
      const res = await data.getResults(modelID);
      this.files = _.orderBy(res, ["id"], ["desc"]);
    },
  },
  created() {
    this.getResults();
  },
};
</script>

<style scoped></style>
